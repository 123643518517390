<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >{{ $t('dynamic_fields::field_types.columns.code').ucFirst() }}</label
                    >

                    <input
                        id="code"
                        v-model="model.code"
                        :class="{'border-danger': states.code === false}"
                        :placeholder="$t('dynamic_fields::field_types.placeholders.code').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('dynamic_fields::field_types.columns.name').ucFirst() }}</label>
                    <div class="col-lg-10">
                        <input
                            id="name"
                            v-model="model.name"
                            :class="{'border-danger': states.name === false}"
                            :placeholder="$t('dynamic_fields::field_types.placeholders.name').ucFirst()"
                            class="form-control"
                            name="name"
                            type="text"
                        />
                        <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{
                                errors.name
                            }}
                        </div>
                    </div>

                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('dynamic_fields::field_types.columns.translation_key').ucFirst() }}</label>
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('dynamic_fields::field_types.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                </div>
            </div>
            <div v-if="model.options" class="row mb-4">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.options === false}"
                        class="form-label"
                        for="options"
                    >{{ $t('dynamic_fields::field_types.columns.type').ucFirst() }}</label>
                    <select
                        id="type"
                        v-model="model.options.type"
                        :class="{'border-danger': states.options === false}"
                        class="form-select"
                        name="type"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('dynamic_fields::field_types.placeholders.type').ucFirst() }}
                        </option>
                        <option v-for="(type, index) in types" :key="index" :value="type">
                            {{ $t('dynamic_fields::field_types.input_types.' + type).ucFirst() }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-10">
                    <div :class="{'d-block': states.options === false}" class="invalid-feedback">
                        {{ errors.options }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import TranslationInput from "../elements/translation-input.vue";

export default {
    name: "FieldTypeForm",

    emits: ['saved'],

    components: {TranslationInput},

    props: {
        fieldType: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'fieldType/loading',
            'states': 'fieldType/states',
            'errors': 'fieldType/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            types: [
                'datetime-local',
                'number',
                'text',
                'email',
                'tel',
                'url',
                'textarea',
                'checkbox',
                'radio',
                'select',
                'file'
            ],
            model: Object.assign({options: {},}, JSON.parse(JSON.stringify(this.fieldType))),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`fieldType/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/field-types/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        fieldType: function () {
            this.model = JSON.parse(JSON.stringify(this.fieldType))
        },
    },

    created() {
        // console.log(this.fieldType)
    }
}
</script>

<style scoped>

</style>
